import { Component, OnInit, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { StockModel } from '../../../model/stock.model';
import { ApiService } from '../../../service/api.service';
import { GroceryItem } from '../../../model/grocery-item.model';

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.css']
})
export class AddNewItemComponent implements OnInit {
  selectedGroceryId : number;
  stock : StockModel = new StockModel(0,0,"",0,0,"","",0,"",0);
  @Input() 
  ItemList : GroceryItem[];

  grocery : GroceryItem;

    constructor(private api : ApiService) { }
  
    ngOnInit() {
     
    }
    
    addNewStock(){
      if(this.stock.itemName == undefined || this.stock.itemName.toString().trim() == ""){
        Swal.fire(
          'Warning',
          'Please enter valid item name',
          'warning'
        )
      }else if(this.stock.quantity == undefined || this.stock.quantity<=0){
        Swal.fire(
          'Warning',
          'Please enter valid number of packets',
          'warning'
        )
      }else if(this.stock.itemSize == undefined || this.stock.itemSize<=0 || this.stock.itemSize.toString().split(".").length > 2 || this.stock.itemSize.toString()=="."){
        Swal.fire(
          'Warning',
          'Please enter valid packet size',
          'warning'
        )
      }else if(this.stock.price == undefined || this.stock.price<=0 || this.stock.price.toString().split(".").length > 2 || this.stock.price.toString()=="."){
        Swal.fire(
          'Warning',
          'Please enter valid total price',
          'warning'
        )
      }else{
        this.api.addStock(this.stock).subscribe(data=>{
          this.grocery = this.ItemList.find(x => x.groceryItemId === data["groceryItemId"]);
          this.grocery.quantity = data["totalQuantity"];

          if(data["groceryItemId"]!= null){
            Swal.fire(
              'Success',
              'Stock Item added successfully',
              'success'
            )
            this.stock = new StockModel(0,0,"",0,0,"","",0,"",0);
            let stockButton : HTMLElement= document.getElementById('stockClose') as HTMLElement;
            stockButton.click();
            
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Not Saved !'
            })
          }
      });
    }
    
  }
    
    openAddStockModel(stock2 : StockModel){    
     this.stock = stock2;      
    }
  
}
