import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from '../model/menuitem.model';
import { ApiService } from '../service/api.service';
import Swal from 'sweetalert2';
import { NewMenuComponent } from './new-menu/new-menu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { UserRatingComponent } from './user-rating/user-rating.component';


@Component({
  selector: 'app-menu-management',
  templateUrl: './menu-management.component.html',
  styleUrls: ['./menu-management.component.css']
})
export class MenuManagementComponent implements OnInit {

  menuitem_list: MenuItem[];
  menuitem: MenuItem;
  searchMenuItem: string;

  @ViewChild( EditMenuComponent , {static: true} ) child: EditMenuComponent;
  @ViewChild( UserRatingComponent , {static: true} ) childRating: UserRatingComponent;
  @ViewChild( NewMenuComponent , {static: true} ) nmc_child: NewMenuComponent;


  
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private api: ApiService) {
  }
  ngOnInit() {
    this.menuitem_list = Array<MenuItem>();
    this.api.getAllMenuItems().subscribe(data => {
      this.menuitem_list = data["MenuItemList"];
    })
    this.menuitem = new MenuItem(0, "","", 0, "disable",0.0,"inactive", "", 0);
    this.searchMenuItem = "";
    var x = document.getElementById("headermain");
    var y = document.getElementById("sidebarmain");
    x.style.display = "grid";
    y.style.display = "block";
  }
  
      deleteMenuItem(menuitem: MenuItem) {
        Swal.fire({
          title: 'Are you sure you want to delete?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.menuitem = menuitem;
            this.api.deleteMenuItem(this.menuitem).subscribe(data => {
              if (data["message"] == "deleted successfully!") {
                this.menuitem_list = this.menuitem_list.filter(mi => mi.id != this.menuitem.id);
                Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
              }
            })
          }
        })
      }  
      
     changeItemLevelFeedbackCheckBox(menuitem: MenuItem) {
    
        this.menuitem = menuitem;
        if (this.menuitem.item == 'inactive') {
          this.menuitem.item = 'active';
          this.api.updateMenuOfTheDay(this.menuitem_list).subscribe(data => {
          Swal.fire(
              'Success',
              'Activated successfully',
              'success'
            )
          });
        }else {
        this.menuitem.item = 'inactive';
        this.api.updateMenuOfTheDay(this.menuitem_list).subscribe(data => {
        
          Swal.fire(
            'Success',
            'Inactivated successfully',
            'success'
          )
        });
      }
    }

    menuOfTheDay() {
    this.api.updateMenuOfTheDay(this.menuitem_list).subscribe(data => {
      Swal.fire(
        'Success',
        'Updated Successfully',
        'success'
      )
    });
  }

    changeMenuOfDayCheckBox(menuitem: MenuItem) {
    this.menuitem = menuitem;
    
    if (this.menuitem.status == 'disable') {
      this.menuitem.status = 'enable';
    }
    else {
      this.menuitem.status = 'disable';
      // this.api.updateMenuOfTheDay(this.menuitem_list).subscribe(data => {
      //   Swal.fire(
      //     'Success',
      //     'Removed successfully',
      //     'success'
      //   )
      // });
    }
  }

    showEditMenuModel(menuitem: MenuItem) { 
      this.child.setdata(menuitem);
  }
    openRatingModel(itemId: number){
      this.childRating.showModel(itemId);
    }
    testMethod(testName : MenuItem[]){
     this.menuitem_list=testName;
    }

    openNewMenu(){
      this.menuitem = new MenuItem(0, "","", 0, "disable",0.0,"inactive", "", 0);
      this.nmc_child.openAddNewMenuModel(this.menuitem);
    }
}
