
export class MenuSuggestion{
    suggestionId: number;
    name: string;
    categoryName: string;
    dateTime: string;
    suggestion:string;


    constructor(suggestionId:number,name: string,categoryName: string,dateTime: string,suggestion:string) {
        this.suggestionId = suggestionId;
        this.name = name;
        this.categoryName = categoryName;
        this.dateTime = dateTime;
        this.suggestion = suggestion;
    }
}