
export class WeeklyMenu{
    weeklyMenuId: number;
    dayId : number
    status : string;
    day: string;
    breakfast: string;
    lunch: string;
    eveningSnack:string;
    dinner:string;
    breakfastHindi: string;
    lunchHindi: string;
    eveningSnackHindi:string;
    dinnerHindi:string;

    constructor(weeklyMenuId:number,dayId : number,status : string,day: string,breakfast: string,lunch: string,eveningSnack:string, dinner:string, breakfastHindi: string,lunchHindi: string,eveningSnackHindi:string,dinnerHindi:string) {
        this.weeklyMenuId = weeklyMenuId;
        this.dayId = dayId;
        this.status=status;
        this.day = day;
        this.breakfast = breakfast;
        this.lunch = lunch;
        this.eveningSnack = eveningSnack;
        this.dinner = dinner;
        this.breakfastHindi = breakfastHindi;
        this.lunchHindi = lunchHindi;
        this.eveningSnackHindi = eveningSnackHindi;
        this.dinnerHindi = dinnerHindi;
    }
}