import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { User } from '../model/user.model';
import { MenuItem } from '../model/menuitem.model';
import { TransactionDetailsForAdmin } from '../model/transactiondetails-foradmin.model';
import { CategoryItem } from '../model/category-item.model';
import { ChangePassword } from '../model/changepassword.model';
import { ItemRating } from '../model/item-rating.modal';
import { TransactionModel } from '../model/transaction-model';
import { StockModel } from '../model/stock.model';
import { GroceryItem } from '../model/grocery-item.model';
import { GroceryTransactionModel } from '../model/grocery-transaction.model';
import { WeeklyMenu } from '../model/weeklymenu.model';
import { WeekDays } from '../model/week-days.model';
import { MenuSuggestion } from '../model/menu-suggestion.model';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ApiService {
  apiURL:string;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public  pageTitle: string ;
  constructor(private httpClient:HttpClient) {

    this.apiURL = environment.apiURL;
    // this.headers.set("Content-Type","application/json; charset=utf-8");

     //this.apiURL = "http://qa-snaxgenie.genesisapps.in/canteenApp/";
    // this.apiURL = "http://192.168.225.84:8081/canteenApp/";
    //this.apiURL = "http://snaxgenie.genesisapps.in:8081/canteenApp/";
    //updated api url
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
}

  getAllUser(): Observable<Map<string,User[]>>{
    this.pageTitle="User Management";
    return this.httpClient.get<Map<string,User[]>>(this.apiURL+"user/admin/getAllUserWithWallet");
  }

  getAllMenuItems(): Observable<Map<string,MenuItem[]>>{
    this.pageTitle="Menu Management";
    return this.httpClient.get<Map<string,MenuItem[]>>(this.apiURL+"menu/admin/getAllMenuItems");
  }
  getAllWeeklyMenuItems(): Observable<Map<string,WeeklyMenu[]>>{
    this.pageTitle="Weekly Menu";
    return this.httpClient.get<Map<string,WeeklyMenu[]>>(this.apiURL+"menu/admin/getAdminWeeklyMenu");
  }

  getAllMenuSuggestion(): Observable<Map<string,User[]>>{
    this.pageTitle="Menu Suggestion";
    return this.httpClient.get<Map<string,User[]>>(this.apiURL+"menu/admin/getAllMenuSuggestion");
  }

  getAllTransactionForAdmin(userName : string): Observable<Map<string,TransactionDetailsForAdmin[]>>{
   this.pageTitle = "Transaction History";
    return this.httpClient.get<Map<string,TransactionDetailsForAdmin[]>>(this.apiURL+"transaction/admin/getAllTransactionsForAdmin?search="+userName);
  }

  // HttpClient API post() method => Add new Menu item
  addMenuItem(menuItem:MenuItem) : Observable<MenuItem>{

    return this.httpClient.post<MenuItem>(this.apiURL+"menu/admin/addMenuItem",menuItem);
  }

   // HttpClient API post() method => Edit User wallet amount item
   editUserWalletAmount(user:any) : Observable<any>{
     //alert(JSON.stringify(user));

    return this.httpClient.post<any>(this.apiURL+"transaction/admin/saveTransaction",user);
  }


  saveUserInfo(user:any) : Observable<any>{
   return this.httpClient.post<any>(this.apiURL+"user/admin/userInfo",user);
 }

  updateMenuOfTheDay(menuItem_list:MenuItem[]) : Observable<any>{
    return this.httpClient.post<any>(this.apiURL+"menu/admin/saveMenuOfTheDay",menuItem_list);
  }

  deleteMenuItem(menuItem:MenuItem) : Observable<MenuItem>{
    return this.httpClient.delete<MenuItem>(this.apiURL+"menu/admin/deleteMenuItem?menuId="+menuItem.id);
  }

  deleteWeeklyMenu(weeklymenu:WeeklyMenu) : Observable<WeeklyMenu>{
    return this.httpClient.delete<WeeklyMenu>(this.apiURL+"menu/admin/deleteWeeklyMenu?weeklyMenuId="+weeklymenu.weeklyMenuId);
  }

  deleteGroceryTransaction(groceryTransactionList:any) : Observable<any>{
    return this.httpClient.post<any>(this.apiURL+"grocery/admin/deleteGroceryTransactionById",groceryTransactionList);
  }

  // login(userlogin : Map<string,string>): Observable<Map<string,string>>{

  //   return this.httpClient.post<Map<string,string>>(this.apiURL+"user/adminLogin",userlogin);
  // }
  getAllCategoryOfMenuItem() : Observable<Map<string,CategoryItem[]>>{

    return this.httpClient.get<Map<string,CategoryItem[]>>(this.apiURL+"menu/admin/getAllMenuCategory");
  }

  changePassword(changePasswordList:any) :Observable<any>{

    return this.httpClient.post<any>(this.apiURL+"user/admin/changePassword",changePasswordList);
  }
  getItemRatingByItem(itemId:number, fromDate : string, toDate : string) : Observable<Map<string,ItemRating[]>>{
    let url;
    if(fromDate != null && toDate != null)
    url = this.apiURL+"feedback/admin/getAllFeedbackById?itemId="+itemId+"&fromDate="+fromDate+"&toDate="+toDate;
    else
    url = this.apiURL+"feedback/admin/getAllFeedbackById?itemId="+itemId;

    return this.httpClient.get<Map<string, ItemRating[]>>(url);
  }
  getTransactionRecord(selectedUserType: String, userName:string, fromDate:string, toDate : String) : Observable<Map<string,TransactionModel>>{
    this.pageTitle = "User Record";
    return this.httpClient.get<Map<string, TransactionModel>>(this.apiURL+"transaction/admin/getAllTransactionsAmount?type="+selectedUserType+"&name="+userName+"&fromDate="+fromDate+"&toDate="+toDate);
  }

  getStockList() : Observable<Map<string,StockModel>>{
    this.pageTitle = "Grocery Stock";
    return this.httpClient.get<Map<string, StockModel>>(this.apiURL+"grocery/admin/getAllGroceryStock");
  }
  // getGroceryList() : Observable<Map<string,GroceryItem>>{
  //   return this.httpClient.get<Map<string, GroceryItem>>(this.apiURL+"grocery/admin/getAllGroceryItems");
  // }
  addStock(stock : Object) : Observable<Object>{
    return this.httpClient.post<Object>(this.apiURL+"grocery/admin/addGroceryStocks",stock);
  }
  getAllReorderItems() : Observable<Map<string,GroceryItem>>{
    this.pageTitle = "Grocery Reorder";
    return this.httpClient.get<Map<string, GroceryItem>>(this.apiURL+"grocery/admin/getAllReorderItems");
  }

  getAllTransactionOut() : Observable<Map<string,StockModel>>{
    this.pageTitle = "Grocery Pending";
    return this.httpClient.get<Map<string, StockModel>>(this.apiURL+"grocery/admin/getAllPendingGorceryTransaction");
  }

  getAllRecentTransaction() : Observable<Map<string, GroceryTransactionModel>>{
    this.pageTitle = "Last Transaction Item Wise";
    return this.httpClient.get<Map<string, GroceryTransactionModel>>(this.apiURL+"grocery/admin/getAllRecentTransaction");
  }

  updateTransactionOutStatus(stock : StockModel) : Observable<Object>{
    return this.httpClient.post<StockModel>(this.apiURL+"grocery/admin/updateGroceryTransaction",stock);
  }

  getAllGroceryTransaction(type:String, fromDate:string, toDate: string, itemName:string) : Observable<Map<string,GroceryTransactionModel>>{
    this.pageTitle = "Grocery Transaction";
    return this.httpClient.get<Map<string, GroceryTransactionModel>>(this.apiURL+"grocery/admin/getAllGroceryTransaction?status="+type+"&fromDate="+fromDate+"&toDate="+toDate+"&itemName="+itemName);
  }
  addNewGroceryItem(grocery : GroceryItem) : Observable<Object>{
    return this.httpClient.post<StockModel>(this.apiURL+"grocery/admin/addGroceryItems",grocery);
  }

  updateUserStatus(user : User) : Observable<Object>{
    return this.httpClient.post<StockModel>(this.apiURL+"user/admin/userStatus",user);
  }
  getAllGroceryItems() : Observable<any>{
    console.log("getAllGroceryItems");
    this.pageTitle = "Grocery Stock";
    return this.httpClient.get<Map<string, GroceryItem>>(this.apiURL+"grocery/admin/getAllGroceryItems");
  }

  getAllGroceryStockById(id : number) : Observable<any>{

    return this.httpClient.get<Map<string, StockModel>>(this.apiURL+"grocery/admin/getAllGroceryStockByItemId?itemId="+id);
  }
  deleteGroceryStock(stockId:number) : Observable<StockModel>{

    return this.httpClient.delete<StockModel>(this.apiURL+"grocery/admin/deleteGroceryStockById?groceryStockId="+stockId);
  }

  deleteGroceryItem(groceryId:number) : Observable<number>{

    return this.httpClient.delete<number>(this.apiURL+
      "grocery/admin/deleteGroceryItemById?groceryItemId="+groceryId);
  }


  addWeeklyMenu(weeklymenu:WeeklyMenu) : Observable<WeeklyMenu>{

    return this.httpClient.post<WeeklyMenu>(this.apiURL+"menu/admin/addWeeklyMenu",weeklymenu);
  }

  updateWeeklyMenu(weeklymenu:WeeklyMenu) : Observable<WeeklyMenu>{

    return this.httpClient.post<WeeklyMenu>(this.apiURL+"menu/admin/updateWeeklyMenu",weeklymenu);
  }


  updateMenuSuggestion(menuSuggestion : MenuSuggestion) : Observable<Object>{
    return this.httpClient.post<MenuSuggestion>(this.apiURL+"menu/admin/updateMenuSuggestion",menuSuggestion);
  }

  getAllWeekDays() : Observable<Map<string,WeekDays[]>>{
    return this.httpClient.get<Map<string,WeekDays[]>>(this.apiURL+"menu/admin/getAllWeekDays");
  }

}
