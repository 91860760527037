import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GroceryItem } from '../../../model/grocery-item.model';
import { ApiService } from '../../../service/api.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../../../service/global.service';

@Component({
  selector: 'app-edit-grocery',
  templateUrl: './edit-grocery.component.html',
  styleUrls: ['./edit-grocery.component.css']
})
export class EditGroceryComponent implements OnInit {
  ItemList : GroceryItem[];
  grocery : GroceryItem;
  selectedvalue : String ="Select Item Measuring Unit";
  @Output() customEvent = new EventEmitter();
  constructor(private api : ApiService) { }

  ngOnInit() {
    this.grocery = new GroceryItem(0, "", 0,"","", 0,"","");
  }
 
   closePopup() {
    this.api.getAllGroceryItems().subscribe(data=>{
      this.ItemList = data["allGroceryItems"];
      this.customEvent.emit(this.ItemList);
    }) 
  }

  openModel(gitem : GroceryItem){
    this.selectedvalue=gitem.itemUnit;
    this.grocery = gitem; 

  }
  editGroceryItem(){

    let alphabatConstant : RegExp = /[a-zA-Z]+$/;
    let specialConstant : RegExp = /[!@#$&()-`.+,/\"]+$/;
    let numberConstant : RegExp = /[0-9]+$/;


    if(this.grocery.itemName == undefined || this.grocery.itemName.toString().trim() == ""){
      Swal.fire(
        'Warning',
        'Please enter valid item name',
        'warning'
      )
    }else if(this.grocery.displayName == undefined || this.grocery.displayName.toString().trim() == ""  || alphabatConstant.test(this.grocery.displayName.toString()) || specialConstant.test(this.grocery.displayName.toString()) || numberConstant.test(this.grocery.displayName.toString())){
      Swal.fire(
        'Warning',
        'Please select valid item name hindi',
        'warning'
      )
    }else if(this.grocery.itemUnit == undefined || this.grocery.itemUnit == "Select Item Measuring Unit"){
      Swal.fire(
        'Warning',
        'Please select valid measuring unit',
        'warning'
      )
    }else if(this.grocery.reorderQuantity == undefined || this.grocery.reorderQuantity <= 0){
      Swal.fire(
        'Warning',
        'Please enter valid reorder quantity',
        'warning'
      )
    }else {
        this.api.addNewGroceryItem(this.grocery).subscribe(data=>{
          if (data["message"] == "Added Successfully") {
          Swal.fire(
            'Success',
            'Grocery Item Edited Successfully',
            'success'
          )
          let closeButton : HTMLElement= document.getElementById('close-btn') as HTMLElement;
          closeButton.click();
        }else{
          Swal.fire(
            'Warning',
            'Grocery item is already exist',
            'warning'
          )
        }
      });
    }
  }
  selectMeasure(value){
    this.grocery.itemUnit = value;
  }
}
