import { Component, OnInit } from '@angular/core';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common'
import { ApiService } from '../service/api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  public loadGrocery : boolean = false;
  buttonTitle:string ="Grocery";
  isLoggedIn: boolean;
  showGroceryAdd : boolean =false;
  pageHeading :string = "Menu Item";
  landingUrl: string = "/";

  constructor(private route: ActivatedRoute, private location: PlatformLocation,private router: Router, private api : ApiService){

    location.onPopState(() => {
      window.location.reload();
  });

 
  }
  ngOnInit(): void {
    var url = window.location.href;
  }
  toggle() {
    Swal.fire({
      title: 'Are you sure you want to Logout?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      
      if (result.value) {
        this.isLoggedIn = !this.isLoggedIn;
    localStorage.clear();
    this.router.navigate(['/']);
       
      }
    })
  }

   
  
  
LoadGroceryManagement(){
  this.loadGrocery =!this.loadGrocery;
  this.showGroceryAdd =!this.showGroceryAdd;
  if(this.loadGrocery == true){
  this.buttonTitle = "Home";
  
  }
  else{
  this.buttonTitle = "Grocery";
  
  }
  
}
}

