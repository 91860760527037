export class User{
    userId: number;
    name: string;
    walletAmount: number;
    credit: number;
    debit: number;
    status : string;
    nameHindi : String;
    userEmail : String;
    
    

    constructor(userId:number,name: string,walletAmount: number,credit:number,debit:number, status : string,nameHindi : string,userEmail : string) {
        this.userId = userId;
        this.name = name;
        this.walletAmount = walletAmount;
        this.credit = credit;
        this.debit = debit;
        this.status = status;
        this.nameHindi=nameHindi;
        this.userEmail=userEmail;
    }
}