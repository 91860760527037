import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { TransactionModel } from '../model/transaction-model';
import { User } from '../model/user.model';
import { summaryFileName } from '@angular/compiler/src/aot/util';

@Component({
  selector: 'app-user-record',
  templateUrl: './user-record.component.html',
  styleUrls: ['./user-record.component.css']
})
export class UserRecordComponent implements OnInit {
transactionRecordList : User[]; 
transaction : TransactionModel;
selectedUserType : String = "all";
userName : string=null;
fromDate : string = null;
tempdata : User[]=null;
toDate :string=null;
  constructor(private api : ApiService) { }

  ngOnInit() {
    this.loadData();
  }
  Onselect(userType : String){

    this.selectedUserType = userType;
    this.loadData();
}
loadData(){

    this.api.getTransactionRecord(this.selectedUserType, this.userName, this.fromDate, this.toDate).
    subscribe(data => {
    this.transactionRecordList = data["UserList"];
    this.transaction = JSON.parse(JSON.stringify(data));    
   
   },error=>{
    //  alert(JSON.stringify(error));
   })
}

}

