import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from '../app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { MainComponent } from "./app/main.component";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import  { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserRecordComponent } from './user-record/user-record.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenError } from '@angular/compiler/src/ml_parser/lexer';
import { TokenInterceptorService } from '../token-interceptor.service';
import { ApiService } from './service/api.service';
import { CommonModule } from '@angular/common';
import { NewStockComponent } from './stock/item/stock/new-stock/new-stock.component';
import { ReorderComponent } from './reorder/reorder.component';
import { PendingTransactionComponent } from './pending-transaction/pending-transaction.component';
import { GroceryTransactionComponent } from './grocery-transaction/grocery-transaction.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MenuManagementComponent } from './menu-management/menu-management.component';
import { NewMenuComponent } from './menu-management/new-menu/new-menu.component';
import { EditMenuComponent } from './menu-management/edit-menu/edit-menu.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { UserRatingComponent } from './menu-management/user-rating/user-rating.component';
import { RecentTransactionsComponent } from './recent-transactions/recent-transactions.component';
import { NewGroceryComponent } from './stock/new-grocery/new-grocery.component';
import { ItemComponent } from './stock/item/item.component';
import { StockComponent } from './stock/item/stock/stock.component';
import { EditGroceryComponent } from './stock/item/edit-grocery/edit-grocery.component';
import { AddNewItemComponent } from './stock/item/add-new-item/add-new-item.component';
import {TooltipModule} from "ngx-tooltip";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { WeeklyMenuComponent } from './weekly-menu/weekly-menu.component';
import { NewWeeklyMenuComponent } from './weekly-menu/new-weekly-menu/new-weekly-menu.component';
import { EditWeeklyMenuComponent } from './weekly-menu/edit-weekly-menu/edit-weekly-menu.component';
import { MenuSuggestionComponent } from './menu-suggestion/menu-suggestion.component';
 
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    UserRecordComponent,
    NewStockComponent,
    StockComponent,
    NewStockComponent,
    NewGroceryComponent,
    ReorderComponent,
    PendingTransactionComponent,
    GroceryTransactionComponent,
    ChangePasswordComponent,
    MenuManagementComponent,
    NewMenuComponent,
    EditMenuComponent,
    UserManagementComponent,
    TransactionHistoryComponent,
    UserRatingComponent,
    RecentTransactionsComponent,
    ItemComponent,
    StockComponent,
    EditGroceryComponent,
    AddNewItemComponent,
    AddNewItemComponent,
    WeeklyMenuComponent,
    NewWeeklyMenuComponent,
    EditWeeklyMenuComponent,
    WeeklyMenuComponent,
    NewWeeklyMenuComponent,
    EditWeeklyMenuComponent,
    MenuSuggestionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    CommonModule,
    TooltipModule,
    NgbModule
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
    , ApiService
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
