import { Component, OnInit } from '@angular/core';
import { User } from '../model/user.model';
import { ApiService } from '../service/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  user_list: User[];
  searchUserDetail: string;
  user: User;
  amount: number;
  t_type: string;
  status: string;
  userId: number;
  isNameFound: boolean = false;

  constructor(private api: ApiService) { }

  ngOnInit() {

    this.amount = 0;
    this.searchUserDetail = "";
    this.user = new User(0, "", 0, 0, 0, "", "","");
    this.t_type = "credit";
    this.amount = 0;
    this.status = null;

    this.user_list = Array<User>();
    this.api.getAllUser().subscribe(data => {
      this.user_list = data["usersWithWallet"];
    })

  }

  showUserModel(user: User) {
    this.user = user;
    this.user.nameHindi ? this.isNameFound = true : this.isNameFound = false;
    this.amount = 0;
  }

  saveUserInfo(user :User){
    let emailConstant: RegExp =   /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (user.userEmail == undefined || user.userEmail.toString().trim() == "" || !emailConstant.test(user.userEmail.toString())) {
      Swal.fire(
        'Warning',
        'Please enter valid email',
        'warning'
      )
      return;
    }else{
      this.api.saveUserInfo(user).subscribe(data => {
        if(data["message"]=="Added Successfully"){
          Swal.fire(
            'Success',
            'Email Added successfully',
            'success'
          ).then(()=>{
            let dismissButton: HTMLElement = document.getElementById('dismiss-button') as HTMLElement;
            dismissButton.click();
            this.user.userEmail = "";
          })
          
        } else {
          Swal.fire(
            'Warning',
            'Email is already exist',
            'warning'
          )
          
        }

      })
    }
  }

  updateUserWallet(user: User, amount: number) {

    var type = this.t_type;
    var walletDetail = {}

    let alphabatConstant: RegExp = /[a-zA-Z\s]+$/;
    let specialConstant: RegExp = /[!@#$&()-`.+,/\"]/;
    let numberConstant: RegExp = /[0-9]+$/;

    if (user.nameHindi == undefined || user.nameHindi.toString().trim() == "" || alphabatConstant.test(user.nameHindi.toString()) || specialConstant.test(user.nameHindi.toString()) || numberConstant.test(user.nameHindi.toString())) {
      Swal.fire(
        'Warning',
        'Please enter valid user name hindi',
        'warning'
      )
      return;
    }

    if (amount <= 0) {
      Swal.fire(
        'Warning',
        'Please enter correct amount',
        'warning'
      )
      return;
    }

    if (this.t_type == "credit") {
      walletDetail = {
        "userId": user.userId,
        "credit": amount,
        "nameHindi": user.nameHindi,
        "adminUserId": localStorage.getItem('adminUserId')
      }
    } else {
      if (amount > this.user.walletAmount) {
        Swal.fire(
          'Warning',
          'Please Enter correct amount',
          'warning'
        )
        return;
      }
      else {
        walletDetail = {
          "userId": user.userId,
          "debit": amount,
          "nameHindi": user.nameHindi,
          "adminUserId": localStorage.getItem('adminUserId')
        }
      }
    }
    this.api.editUserWalletAmount(walletDetail).subscribe(data => {
      user.walletAmount = data["walletAmount"];
      if (this.t_type == "credit") {
        Swal.fire(
          'Success',
          'Recharge successfully',
          'success'
        )

      } else {
        Swal.fire(
          'Success',
          'Refund successfully',
          'success'
        )
      }

      let dismissButton: HTMLElement = document.getElementById('dismiss-btn') as HTMLElement;
      dismissButton.click();
      this.amount = null
    })

  }
  userActivityAction(userId: number, status: string) {

    if (status == "active") {
      this.status = "block";
    } else {
      this.status = "active";
    }
    this.api.updateUserStatus(new User(userId, "", 0, 0, 0, this.status, "","")).subscribe(data => {
      if (status == "active") {
        Swal.fire(
          'Success',
          'Deactivated Successfully',
          'success'
        )
        this.user_list = Array<User>();
        this.api.getAllUser().subscribe(data => {
          this.user_list = data["usersWithWallet"];
        })
      } else {
        Swal.fire(
          'Success',
          'Activated Successfully',
          'success'
        )
        this.user_list = Array<User>();
        this.api.getAllUser().subscribe(data => {
          this.user_list = data["usersWithWallet"];
        })

      }
    })

  }
}
