import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '../../model/menuitem.model';
import Swal from 'sweetalert2';
import { ApiService } from '../../service/api.service';
import { CategoryItem } from '../../model/category-item.model';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.css']
})
export class EditMenuComponent implements OnInit {
  
  menuitem_list: MenuItem[];
  menuitem: MenuItem;
  categoryitem_list: CategoryItem[] = [];

  @Output() customEvent = new EventEmitter();
  constructor(private api: ApiService) {

  }

  ngOnInit() {
    this.categoryitem_list = Array<CategoryItem>();
    this.api.getAllCategoryOfMenuItem().subscribe(data => {
      this.categoryitem_list = data["categories"];
      console.log(this.categoryitem_list)
    })
    this.menuitem = new MenuItem(0, "", "", 0, "disable", 0.0, "inactive", "", 0);
  }
  closePopup() {
    this.api.getAllMenuItems().subscribe(data => {
      this.menuitem_list = data["MenuItemList"];
      this.customEvent.emit(this.menuitem_list);
    }) 
  }
  updateMenuItemOnClick() {

    let alphabatConstant : RegExp = /[a-zA-Z]+$/;
    let specialConstant : RegExp = /[!@#$&()-`.+,/\"]+$/;
    let numberConstant : RegExp = /[0-9]+$/;

    if(this.menuitem.itemName == undefined || this.menuitem.itemName.toString().trim()==""){
      Swal.fire(
        'Warning',
        'Please enter valid item name',
        'warning'
      )
    } else if(this.menuitem.itemNameHindi == undefined || this.menuitem.itemNameHindi.toString().trim()=="" || alphabatConstant.test(this.menuitem.itemNameHindi.toString()) || specialConstant.test(this.menuitem.itemNameHindi.toString()) || numberConstant.test(this.menuitem.itemNameHindi.toString())){
      Swal.fire(
        'Warning',
        'Please enter valid item name hindi',
        'warning'
      )
    }else if (this.menuitem.itemPrice == undefined || this.menuitem.itemPrice<=0){
      Swal.fire(
        'Warning',
        'Please enter valid item price ',
        'warning'
      )
    }else if (this.menuitem.categoryId == undefined || this.menuitem.categoryId<=0){
      Swal.fire(
        'Warning',
        'Please select valid category',
        'warning'
      )
    }else{
      this.api.addMenuItem(this.menuitem).subscribe(data => {
        if(data["message"]="Added Successfully")
        this.menuitem.id = data["id"];
        this.menuitem.categoryName = data["categoryName"];
        if (data["id"] != null) {
        Swal.fire(
          'Success',
          'Menu Item updated successfully',
          'success'
        )
        let dismissCloseBtn : HTMLElement = document.getElementById('dismiss-close') as HTMLElement;
        dismissCloseBtn.click();
      }else{
        Swal.fire(
          'Warning',
          'Menu item is already exist',
          'warning'
        )
      }
      })
      
     
    }
  }
  setdata(menuitem: MenuItem) {
    this.menuitem = menuitem;
  }
}
