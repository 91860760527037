import { Injectable } from '@angular/core';
import { ItemComponent } from '../stock/item/item.component';
import { WeeklyMenuComponent } from '../weekly-menu/weekly-menu.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  itemComponent: ItemComponent;

  weeklyMenuComponent: WeeklyMenuComponent;



  public setItemComponent(itemComponent:ItemComponent){
    this.itemComponent=itemComponent;
  }

  public getItemComponent():ItemComponent{
    return this.itemComponent;
  }


  public setWeeklyMenuComponent(weeklyMenuComponent:WeeklyMenuComponent){
    this.weeklyMenuComponent=weeklyMenuComponent;
  }

  public getWeeklyMenuComponent():WeeklyMenuComponent{
    return this.weeklyMenuComponent;
  }


  validateName(name : string){
    if(name == undefined || name.trim() == ""){
      return false;
    }
    else{
      if(/^[A-Za-z]+$/.test(name))
        return true;
      else
        return false;
    }
  }
}
