import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { ApiService } from '../service/api.service';
import { ChangePassword } from '../model/changepassword.model';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordAdmin: ChangePassword;
  changePasswordAdminList: ChangePassword[];


  constructor(private api : ApiService) { }

  ngOnInit() {
    this.changePasswordAdmin = new ChangePassword("", "", "");

  }

  closeModal(){
    this.changePasswordAdmin.email="";
    this.changePasswordAdmin.password="";
    this.changePasswordAdmin.oldPassword="";
  }


  changePassword() {
    let emailConstant : RegExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;  
    if(this.changePasswordAdmin.email == undefined || this.changePasswordAdmin.email.toString()=="" || !emailConstant.test(this.changePasswordAdmin.email.toString())){
      Swal.fire(
        'Warning',
        'Please enter valid email',
        'warning'
      )
    } else if(this.changePasswordAdmin.oldPassword == undefined || this.changePasswordAdmin.oldPassword.toString()==""  ){
      Swal.fire(
        'Warning',
        'Please enter valid old password',
        'warning'
      )
    }else if (this.changePasswordAdmin.password == undefined || this.changePasswordAdmin.password.toString()==""){
      Swal.fire(
        'Warning',
        'Please enter valid password',
        'warning'
      )
    }else {
      this.api.changePassword(this.changePasswordAdmin).subscribe(data => {
        if (data.message == "Change Passowrd Successful"){
          Swal.fire(
            'Success',
            'Password Successfully Changed',
            'success'
          )
          this.changePasswordAdmin.email="";
          this.changePasswordAdmin.oldPassword="";
          this.changePasswordAdmin.password="";
          let changePasswordButton : HTMLElement= document.getElementById('changepassword-btn') as HTMLElement;
          changePasswordButton.click();
        }
        else
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Changing Password Failed!'
          })

      });
    }
  }

}
