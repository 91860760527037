export class GroceryItem{
    // groceryItemId: number;
    // itemName: String;
    // reorderQuantity: number;
    // itemUnit:String;
    // displayName: String;
    // leftQuantity : number;
    // lastTransaction:string;
   quantity : number;
    

    constructor (public groceryItemId: number,  public itemName: String,public reorderQuantity: number,public itemUnit:String,
        public displayName: String, public leftQuantity : number,
        public lastTransaction:string,public message : string){
       
    }
}