import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './app/service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    
    const userToken = localStorage.getItem("token");
    console.log(userToken);
    if(userToken == null && req.url.endsWith("/adminLogin")){
        
    }
   else if(userToken == null)
    this.router.navigate(['/']);

    else{
        req = req.clone({
            setHeaders:{
              Authorization : `Token ${userToken}`
            }
          })
    }
     
    return next.handle(req);
}
  
}