import { Component, OnInit, Type } from '@angular/core';
import { GroceryTransactionModel } from '../model/grocery-transaction.model';
import { ApiService } from '../service/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grocery-transaction',
  templateUrl: './grocery-transaction.component.html',
  styleUrls: ['./grocery-transaction.component.css']
})
export class GroceryTransactionComponent implements OnInit {
groceryTransactionList : GroceryTransactionModel[];
groceryTransaction: GroceryTransactionModel;
gtransactionType: string = "all";
totalPrice : number=0;
fromDate : string=null;
toDate : string=null;
itemName : string=null;
  constructor(private api :ApiService) { }

  ngOnInit() {
this.loadData();
  }
loadData(){
this.api.getAllGroceryTransaction(this.gtransactionType, this.fromDate,this.toDate, this.itemName).subscribe(data=>{
  this.groceryTransactionList = data["groceryTransactionList"];
  this.totalPrice = data["totalAmount"];
});
}
selectType(type : string){
  this.gtransactionType = type;
  this.loadData();
}

deleteGroceryTransaction(groceryTransaction: GroceryTransactionModel) {
  Swal.fire({
    title: 'Are you sure you want to delete?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    
    if (result.value) {
      this.groceryTransaction = groceryTransaction;
      this.api.deleteGroceryTransaction(this.groceryTransaction).subscribe(data => {
      if (data==1) {
        this.groceryTransactionList = this.groceryTransactionList.filter(gt => gt.groceryTransactionId != this.groceryTransaction.groceryTransactionId);
        Swal.fire('Deleted!','Deleted Successfully', 'success')
      }else{
            Swal.fire('Warning!','Item can not be deleted.', 'warning')
      }
  })
}
})
}  
}
