import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { StockModel } from '../../../model/stock.model';
import { NewStockComponent } from './new-stock/new-stock.component';
import Swal from 'sweetalert2';
import { identity } from 'rxjs';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent implements OnInit {

  @ViewChild( NewStockComponent , {static: true} ) ns_child: NewStockComponent;

  constructor(private api : ApiService) { }
itemName : String="";
groceryItemId : number =0;
stockList : StockModel[];
stockParent: StockModel|any = new StockModel(0,0,"",0,0,"","",0,"",0);
  ngOnInit() {
  }
  loadData(groceryItemId : number,itemName : String ){ 
    
    this.api.getAllGroceryStockById(groceryItemId).subscribe(data=>{
    this.stockList = data["groceryStockByItemId"];

    this.itemName = itemName;
  
    },error=>{

    })
  }
  
  openData(stock : StockModel){
    this.stockParent = stock;
    // this.ns_child.openAddStockModel(stock);
    // this.ns_child.stock = stock;
    // this.ns_child.changeDetector.detectChanges();
  }
  deleteStock(stock : StockModel){
   
  //   this.api.deleteGroceryStock(groceryStockId).subscribe(data=>{
      
  //   },error=>{

  //   })
  Swal.fire({
    title: 'Are you sure you want to delete?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    
    if (result.value) {
     this.api.deleteGroceryStock(stock.groceryStockId).subscribe(data=>{
      
       this.stockList = this.stockList.filter(st => st.groceryStockId != stock.groceryStockId);
     },error=>{

     })
      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    }
  })
}

}
