import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from '../../model/menuitem.model';
import { CategoryItem } from '../../model/category-item.model';
import { ApiService } from '../../service/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.css']
})
export class NewMenuComponent implements OnInit {
   new_menuitem: MenuItem;
   menu: MenuItem;
   ratingVar: any ='-';
   categoryitem_list: CategoryItem[] = [];
     @Input() menuitem_list: MenuItem[];
     selectedvalue : string = "Select Category";
     defaultVal : string = "";
     
  constructor(private api : ApiService, private  router:Router) { }

  ngOnInit() {
      this.categoryitem_list = Array<CategoryItem>();
      this.api.getAllCategoryOfMenuItem().subscribe(data => {
      this.categoryitem_list = data["categories"];

    })
    this.new_menuitem = new MenuItem(0, "","",0, "disable",0.0,"inactive", "", 0);

  }

  closeModal(){
    this.new_menuitem.itemName="";
    this.new_menuitem.itemNameHindi="";
    this.new_menuitem.itemPrice=null;
    this.new_menuitem.categoryId=0;
  }
    addMenuItem() {
       let alphabatConstant : RegExp = /[a-zA-Z]+$/;
       let specialConstant : RegExp = /[!@#$&()-+"]/;
       let numberConstant : RegExp = /[0-9]+$/;
      
      if(this.new_menuitem.itemName == undefined || this.new_menuitem.itemName.toString().trim()==""){
        Swal.fire(
          'Warning',
          'Please enter valid item name',
          'warning'
        )
      } else if(this.new_menuitem.itemNameHindi == undefined || this.new_menuitem.itemNameHindi.toString().trim()=="" || alphabatConstant.test(this.new_menuitem.itemNameHindi.toString().trim()) || specialConstant.test(this.new_menuitem.itemNameHindi.toString().trim()) || numberConstant.test(this.new_menuitem.itemNameHindi.toString().trim())){
        Swal.fire(
          'Warning',
          'Please enter valid item name hindi',
          'warning'
        )
      }else if (this.new_menuitem.itemPrice == undefined || this.new_menuitem.itemPrice<=0){
        Swal.fire(
          'Warning',
          'Please enter valid item price',
          'warning'
        )
      }else if (this.new_menuitem.categoryId == undefined || this.new_menuitem.categoryId<=0){
        Swal.fire(
          'Warning',
          'Please select valid category',
          'warning'
        )
      }else{
        this.api.addMenuItem(this.new_menuitem).subscribe(data => {
          if(data["message"]=="Added Successfully"){
            this.new_menuitem.id = data["id"];
            this.new_menuitem.categoryName = data["categoryName"];
            this.new_menuitem.averageRating = this.ratingVar;
            this.menuitem_list.push(this.new_menuitem);
            Swal.fire(
              'Success',
              'Menu Item added successfully',
              'success'
            ).then(()=>{
             
              window.location.reload();
            })
           
           this.new_menuitem = new MenuItem(0, "","",null, "disable",0.0,"inactive", "", 0);
            let dismissButton : HTMLElement= document.getElementById('dismiss-btn') as HTMLElement;
            dismissButton.click();
          } else {
            Swal.fire(
              'Warning',
              'Menu item is already exist',
              'warning'
            )
          }
        })
    }
  }


  openAddNewMenuModel(menu : MenuItem){    
    this.menu = menu;      
   }
}
