import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2'
import { LOginService } from '../service/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  usern:string="";
  pwd:string="";
  utype:string="admin";
  token : string;
  constructor(private router: Router,private api:LOginService) { }

  ngOnInit() {
    var x = document.getElementById("headermain");
    var y = document.getElementById("sidebarmain");
    x.style.display = "none";
    y.style.display = "none";
    this.token = localStorage.getItem("token");
    if(this.token ! = null)
    this.router.navigate(['/menu-item']);
  }

  login(formGroup: FormGroup){
    console.log("formGroup : ")
    console.log(formGroup.valid)
    // if(formGroup.invalid) {
    //   for(var key in formGroup.controls) {
    //       if(formGroup.controls[key].invalid){
    //         formGroup.controls[key].markAsTouched();
    //       }
    //   }
    //   return;
    // }
    
      var userlogin : any = {
        "email":this.usern,
        "password":this.pwd,
        "userType":this.utype
      }
      let emailConstant : RegExp = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;  
      if(this.usern == undefined || this.usern.toString()=="" || !emailConstant.test(this.usern.toString())){
        Swal.fire(
          'Warning',
          'Please enter valid email',
          'warning'
        )
      }else if(this.pwd == undefined || this.pwd.toString()==""){
        Swal.fire(
          'Warning',
          'Please enter valid password',
          'warning'
        )
      }else{
        document.getElementById("loading").classList.remove("invisible");
        document.getElementById("loading").classList.add("visible");
        
      this.api.login(userlogin).subscribe(data=>{
        document.getElementById("loading").classList.add("invisible");
        document.getElementById("loading").classList.remove("visible");
        
        if(data["message"]=="Login successful"){
          localStorage.setItem('token', data["token"]);
          localStorage.setItem('adminUserId', data["userId"]);
          this.router.navigate(['menu-item']);
          var x = document.getElementById("headermain");
          var y = document.getElementById("sidebarmain");
          x.style.display = "grid";
          y.style.display = "block";
          Swal.fire(
            'Success',
            'Login Successful',
            'success'
          )
         }else{
          Swal.fire(
            'Warning',
            'Invalid Login',
            'warning'
          )
          //  alert(data["message"]);
         }
      })
    }
  }
  
}
