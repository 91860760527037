import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { GroceryItem } from '../model/grocery-item.model';

@Component({
  selector: 'app-reorder',
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.css']
})
export class ReorderComponent implements OnInit {
reordergroceryList : GroceryItem[];
  constructor(private api : ApiService) { }

  ngOnInit() {
    this.loadReorderItems();
  }
loadReorderItems(){
  this.api.getAllReorderItems().subscribe(data=>{
    this.reordergroceryList = data["reorderList"];
  });
}
}
