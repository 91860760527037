import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./app/home/home.component";
import { MainComponent } from "./app/app/main.component"; 
import { ReorderComponent } from './app/reorder/reorder.component';
import { PendingTransactionComponent } from './app/pending-transaction/pending-transaction.component'
import { ChangePasswordComponent } from './app/change-password/change-password.component';
import { MenuManagementComponent } from './app/menu-management/menu-management.component';
import { UserRecordComponent } from './app/user-record/user-record.component';
import { GroceryTransactionComponent } from './app/grocery-transaction/grocery-transaction.component';
import { UserManagementComponent } from './app/user-management/user-management.component';
import { TransactionHistoryComponent } from './app/transaction-history/transaction-history.component';
import { RecentTransactionsComponent } from './app/recent-transactions/recent-transactions.component';
import { ItemComponent } from './app/stock/item/item.component';
import { WeeklyMenuComponent } from './app/weekly-menu/weekly-menu.component';
import { MenuSuggestionComponent } from './app/menu-suggestion/menu-suggestion.component';


const routes: Routes = [
  {
    path: 'user-record',
    component:UserRecordComponent
  },
  {
    path: 'transaction-history',
    component:TransactionHistoryComponent
  },
  {
    path: 'user-management',
    component:UserManagementComponent
  },
  {
    path: '',
    component:HomeComponent
  },
  {
    path: 'main',
    component:MainComponent
  },
  {
    path:"menu-item",
    component:MenuManagementComponent
  },
  {
    path:"weekly-menu",
    component:WeeklyMenuComponent
  },

  {
    path:"menu-suggestion",
    component:MenuSuggestionComponent
  },
  {
    path:"grocery-stock",
    component:ItemComponent
  },
  {
    path:"grocery-transaction",
    component:GroceryTransactionComponent
  },
  
  {
    path:"grocery-pending",
    component:PendingTransactionComponent
  },
  {
    path: 'recent-transactions',
    component:RecentTransactionsComponent
  },
  {
    path:"grocery-reorder",
    component:ReorderComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
  