
export class StockModel {
    public groceryStockId : number;
    public groceryItemId : number;
    public itemName : String;
    public itemSize: number;
    public quantity : number;
    public itemUnit : String;
    public dateTime : String;
    public groceryTransactionId : number;
    public status: String;
    public price : number;

    constructor(  groceryStockId : number, groceryItemId : number, itemName : String, itemSize: number, quantity : number, itemUnit : string,
        dateTime : String, groceryTransactionId : number, status: String, price : number){
            // this.groceryStockId = groceryStockId;
            // this.groceryItemId = groceryItemId;
            // this.itemName = itemName;
            // this.itemSize = itemSize;
            // this.quantity = quantity;
            // this.itemUnit = itemUnit;
            // this.dateTime = dateTime;
            // this.groceryTransactionId = groceryTransactionId;
            // this.status = status;
            // this.price = price;
    }

}