
export class MenuItem{
    id: number;
    itemName: string;
    itemNameHindi: string;
    itemPrice: number;
    status:string;
    averageRating:number;
    item:string;
    categoryName:string;
    categoryId:number;

    constructor(id:number,itemName: string,itemNameHindi: string,itemPrice: number,status:string,averageRating:number,itemFeedback:string,categoryName:string,categoryId:number) {
        this.id = id;
        this.itemName = itemName;
        this.itemNameHindi = itemNameHindi;
        this.itemPrice = itemPrice;
        this.status = status;
        this.averageRating = averageRating;
        this.item = itemFeedback;
        this.categoryName = categoryName;
        this.categoryId = categoryId;

    }
}