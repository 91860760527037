import { Component, OnInit, ViewChild } from '@angular/core';
import { NewStockComponent } from './stock/new-stock/new-stock.component';
import { StockModel } from '../../model/stock.model';
import { ApiService } from '../../service/api.service';
import { GroceryItem } from '../../model/grocery-item.model';
import { StockComponent } from './stock/stock.component';
import { EditGroceryComponent } from './edit-grocery/edit-grocery.component';
import { AddNewItemComponent } from './add-new-item/add-new-item.component';
import Swal from 'sweetalert2';
import { GlobalService } from '../../service/global.service';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  @ViewChild( NewStockComponent , {static: true} ) child: NewStockComponent;
  @ViewChild( StockComponent , {static: true} ) s_child: StockComponent;
  @ViewChild( EditGroceryComponent , {static: true} ) gitem_child: EditGroceryComponent;
  
  stock : StockModel = new StockModel(0,0,"",0,0,"","",0,"",0);
  
  ItemList : GroceryItem[];
  searchStockItem :string;
  groceryItemId: number;
  groceryItemName: String;
  isExpanded : boolean = false;
  groceryItem : GroceryItem;
  @ViewChild( AddNewItemComponent , {static: true} ) ns_child: AddNewItemComponent;

  constructor(private api : ApiService,private globalService:GlobalService) { }

  ngOnInit() {
    this.globalService.setItemComponent(this);
    this.loadData();
  }
loadData(){
this.api.getAllGroceryItems().subscribe(data=>{
this.ItemList = data["allGroceryItems"];
},error=>{

})

}
openData(grocery : GroceryItem){
  this.stock =  new StockModel(0,0,"",0,0,"","",0,"",0);
  this.stock.itemName = grocery.itemName;
  this.stock.itemUnit = grocery.itemUnit;
  this.stock.groceryItemId = grocery.groceryItemId;
  this.ns_child.openAddStockModel(this.stock);

}
loadList(){
 this.child.loadGroceryList();
}
stockOpen(grocery: GroceryItem){
  this.groceryItemId = grocery.groceryItemId;
  this.groceryItemName = grocery.itemName;
  this.s_child.loadData(this.groceryItemId,this.groceryItemName);
  this.isExpanded = !this.isExpanded;

}
openeditModel(gitem : GroceryItem){
this.gitem_child.openModel(gitem);
}
deleteGroceryItem(grocery : GroceryItem){
  Swal.fire({
    title: 'Are you sure you want to delete?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if(result.value){
    this.api.deleteGroceryItem(grocery.groceryItemId).subscribe(data=>{
      if(data==1){
        var index = this.ItemList.indexOf(grocery);
        this.ItemList.splice(index,1);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }else{
        Swal.fire(
          'Warning',
          'file can not deleted',
          'warning'
        )
      }
     
      });
    }

 },error=>{

 });
      
  }

  testMethod(testName : GroceryItem[]){
    this.ItemList=testName;
   }

}
