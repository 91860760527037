import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../model/user.model';
import { MenuItem } from '../model/menuitem.model';
import { TransactionDetailsForAdmin } from '../model/transactiondetails-foradmin.model';
import { CategoryItem } from '../model/category-item.model';
import { ChangePassword } from '../model/changepassword.model';
import { ItemRating } from '../model/item-rating.modal';
import { TransactionModel } from '../model/transaction-model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class LOginService {
  apiURL:string;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;


  constructor(private httpClient:HttpClient) {


    // this.headers.set("Content-Type","application/json; charset=utf-8");
    this.apiURL = environment.apiURL;
    //this.apiURL = "http://192.168.225.84:8081/canteenApp/";
   //this.apiURL = "http://genesisapps.in:8081/canteenApp/";

   //this.apiURL = "http://snaxgenie.genesisapps.in:8081/canteenApp/";
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
}


  login(userlogin : Map<string,string>): Observable<Map<string,string>>{

    return this.httpClient.post<Map<string,string>>(this.apiURL+"user/adminLogin",userlogin
);
  }

}
