import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../service/api.service';
import { MenuItem } from '../model/menuitem.model';
import Swal from 'sweetalert2';
import { WeeklyMenu } from '../model/weeklymenu.model';
import { EditWeeklyMenuComponent } from './edit-weekly-menu/edit-weekly-menu.component';
import { NewWeeklyMenuComponent } from './new-weekly-menu/new-weekly-menu.component';
import { GlobalService } from '../service/global.service';

@Component({
  selector: 'app-weekly-menu',
  templateUrl: './weekly-menu.component.html',
  styleUrls: ['./weekly-menu.component.css']
})
export class WeeklyMenuComponent implements OnInit {

  weeklymenuList: WeeklyMenu[];
  weeklyMenu: WeeklyMenu;
  searchWeeklyMenu : string;

  @ViewChild( EditWeeklyMenuComponent , {static: true} ) ed_child: EditWeeklyMenuComponent;
  @ViewChild( NewWeeklyMenuComponent , {static: true} ) new_child: NewWeeklyMenuComponent;
  constructor(private api: ApiService,private globalService: GlobalService) {
  }

  ngOnInit() {
    this.weeklymenuList = Array<WeeklyMenu>();
    this.api.getAllWeeklyMenuItems().subscribe(data => {
      this.weeklymenuList = data["weeklyMenuList"];
    })

    this.globalService.setWeeklyMenuComponent(this);
    this.searchWeeklyMenu=""
    this.weeklyMenu = new WeeklyMenu(0, 0,"","","","","","","","","","");
  }

  testMethod(weeklymenu : WeeklyMenu[]){
    this.weeklymenuList=weeklymenu;
   }

  showEditWeeklyMenu(weeklymenu: WeeklyMenu) { 
    this.ed_child.setdata(weeklymenu);
  }

  deleteWeeklyMenu(weeklyMenu: WeeklyMenu) {
    Swal.fire({
      title: 'Are you sure you want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      
      if (result.value) {
        this.weeklyMenu = weeklyMenu;
        this.api.deleteWeeklyMenu(this.weeklyMenu).subscribe(data => {
          if (data["message"] == "deleted successfully!") {
            this.weeklymenuList = this.weeklymenuList.filter(mi => mi.weeklyMenuId != this.weeklyMenu.weeklyMenuId);
            Swal.fire(
              'Deleted!',
              'Weekly Menu Item has been deleted.',
              'success'
            )
          }
        })
      }
    })
  } 
}
