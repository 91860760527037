import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { GroceryItem } from '../../../../model/grocery-item.model';
import { ApiService } from '../../../../service/api.service';
import { StockModel } from '../../../../model/stock.model';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-stock',
  templateUrl: './new-stock.component.html',
  styleUrls: ['./new-stock.component.css']
})
export class NewStockComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes : ")
    console.log(changes)
    console.log("changes.stock : ")
      console.log(changes.stock)
    if(changes.stock != null && changes.stock.currentValue != null && changes.stock.currentValue.itemName != null && changes.stock.currentValue.itemName.trim() != "") {
      this.stock.itemName = changes.stock.currentValue.itemName;
      console.log("this.stock :inside")
      console.log(this.stock)
    }
    //throw new Error("Method not implemented.");
  }
allGroceryList : GroceryItem[];
selectedGroceryId : number;
@Input()
stock : StockModel|any = new StockModel(0,0,"",0,0,"","",0,"",0);
@Input() 
stockList: StockModel[];

msg:string="";

  constructor(private api : ApiService, public changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    
  }
  ngAfterViewInit() {
  //  this.stock  = new StockModel(0,0,"",0,0,"","",0,"",0);
    console.log("inside ngOninit....")
    console.log(this.stock)
  }
  loadGroceryList(){
//   this.api.getGroceryList().subscribe(data=>{
//   this.allGroceryList = data["groceryItem"];
// }
//   );
  }
  addNewStock(){
    if(this.stock.groceryItemId == undefined){
      this.msg = "please select grocery item";
      return;
    }
    if(this.stock.quantity == undefined){
      this.msg = "please enter quantity";
      return;
    }
    if(this.stock.itemSize == undefined){
      this.msg = "please enter item size";
      return;
    }
    if(this.stock.price == undefined){
      this.msg = "please enter price";
      return;
    }
    
    this.api.addStock(this.stock).subscribe(data=>{
    if(data["groceryStockId"] > 0){
      
      var obj = this.stockList.find(x => x.groceryStockId === data["groceryStockId"]);
    
      if(obj == null || obj == undefined){
      this.stock = data as StockModel;
      
      this.stockList.push(this.stock);

      Swal.fire(
        'Success',
        'Stock Item added successfully',
        'success'
      )

      }
      else {
       
        let index = this.stockList.indexOf(obj);
        obj["quantity"] = data["quantity"];
        this.stockList[index] = obj;
        Swal.fire(
          'Success',
          'Stock Item added successfully',
          'success'
        )
      }   
      this.stock = new StockModel(0,0,"",0,0,"","",0,"",0);
  
    }
  });
  this.stock = new StockModel(0,0,"",0,0,"","",0,"",0);
  }
  
  public openAddStockModel(stock2 : StockModel){ 
  //  this.stock = stock2;
     
  }
  getItemName(){
    console.log(JSON.stringify(this.stock.itemName));
  }
  
}


