import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {Injectable, ReflectiveInjector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { ItemRating } from '../../model/item-rating.modal';
import { ModalService } from '../../service/model.service';
import { ApiService } from '../../service/api.service';


@Component({
  selector: 'app-user-rating',
  templateUrl: './user-rating.component.html',
  styleUrls: ['./user-rating.component.css']
})
export class UserRatingComponent implements OnInit {
  ratingList : ItemRating[];
  private modalService: ModalService;
  display='none';
  itemName : String ="";
  fromDate : any;
  toDate : any;
  itemId : number;



constructor(private api: ApiService)
{

}

  ngOnInit() {
    
    
  }
  showModel(itemId : number){
    this.itemId =itemId;
    this.loadData(itemId, null, null );

  }
  loadData(itemId : number, fromDate : string, toDate :string){
  
    this.api.getItemRatingByItem(itemId, fromDate,toDate).subscribe(data => {
      this.ratingList = data["feedback"];
      this.itemName = this.ratingList[0].itemName;
  
    })
  }
  onSelectDate(){
    this.loadData(this.itemId, this.fromDate, this.toDate);
  }

}
