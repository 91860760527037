import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { MenuSuggestion } from '../model/menu-suggestion.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu-suggestion',
  templateUrl: './menu-suggestion.component.html',
  styleUrls: ['./menu-suggestion.component.css']
})
export class MenuSuggestionComponent implements OnInit {

  menuSuggestionList: MenuSuggestion[];
  searchMenuSuggestion: string;
  menuSuggestion: MenuSuggestion;

  constructor(private api : ApiService) { }

  ngOnInit() {
    this.searchMenuSuggestion = "";
    this.menuSuggestion = new MenuSuggestion(0, "", "", "", "");
    this.menuSuggestionList = Array<MenuSuggestion>();
    this.api.getAllMenuSuggestion().subscribe(data => {
      this.menuSuggestionList = data["allMenuSuggestion"];
    })
  }

  updateMenuSuggestionStatus(suggestionId:number){

    this.menuSuggestion.suggestionId = suggestionId;
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to update status!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update status!'
    }).then((result) => {
      if (result.value) {
        this.api.updateMenuSuggestion(this.menuSuggestion).subscribe(data=>{
          if(data["suggestionId"] == this.menuSuggestion.suggestionId){
            let index = this.menuSuggestionList.findIndex(d => d.suggestionId === this.menuSuggestion.suggestionId); //find index in your array
            this.menuSuggestionList.splice(index, 1);//remove element from array
          }
          this.menuSuggestion = new MenuSuggestion(0, "","","","");
           });
        Swal.fire(
          'success!',
          'Sucessfully Updated',
          'success'
        )
      }
    })
    }

}
