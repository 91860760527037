import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { RecentTransaction } from '../model/recent-transaction.model';
import { GroceryTransactionModel } from '../model/grocery-transaction.model';

@Component({
  selector: 'app-recent-transactions',
  templateUrl: './recent-transactions.component.html',
  styleUrls: ['./recent-transactions.component.css']
})
export class RecentTransactionsComponent implements OnInit {
  recentTransactionList: GroceryTransactionModel[];
  constructor(private api : ApiService) { }

  ngOnInit() {
    
    this.loadRecentTransactions()
  }
  loadRecentTransactions(){
    
    this.api.getAllRecentTransaction().subscribe(data=>{
       this.recentTransactionList = data["recentTransactionList"];
    });
  }

}

