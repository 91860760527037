import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { WeeklyMenu } from '../../model/weeklymenu.model';
import { WeekDays } from '../../model/week-days.model';
import { ApiService } from '../../service/api.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../../service/global.service';

@Component({
  selector: 'app-edit-weekly-menu',
  templateUrl: './edit-weekly-menu.component.html',
  styleUrls: ['./edit-weekly-menu.component.css']
})
export class EditWeeklyMenuComponent implements OnInit {

  weeklymenuList: WeeklyMenu[];
  weeklyMenu: WeeklyMenu;
  weekday_list: WeekDays[] = [];

  @Output() customData = new EventEmitter();

  constructor(private api: ApiService,private globalService : GlobalService) {

  }

  ngOnInit() {
   // this.globalService.getWeeklyMenuComponent
    this.weekday_list = Array<WeekDays>();
    this.weekday_list
    this.api.getAllWeekDays().subscribe(data => {
      this.weekday_list = data["weekDays"];
    })
  }

  closePopup() {
    //window.location.reload();
    
    this.api.getAllWeeklyMenuItems().subscribe(data => {
      this.weeklymenuList = data["weeklyMenuList"];
      this.customData.emit(this.weeklymenuList);
    }) 
  }

  selectStatus(status : string){
    this.weeklyMenu.status=status;
  }


  updateWeeklyMenuItem(weeklyMenu : WeeklyMenu) {

    let alphabatConstant : RegExp = /[a-zA-Z]+$/;
    let specialConstant : RegExp = /[!@#$&()-`.+,/\"]+$/;
    let numberConstant : RegExp = /[0-9]+$/;
 
    if(this.weeklyMenu.dayId == undefined || this.weeklyMenu.dayId<=0){
      Swal.fire(
        'Warning',
        'Please select valid day',
        'warning'
      )
    }else  if(this.weeklyMenu.status == undefined || this.weeklyMenu.status==""){
      Swal.fire(
        'Warning',
        'Please select valid week',
        'warning'
      )
    }else if(weeklyMenu.breakfast == undefined || weeklyMenu.breakfast.toString().trim()=="" ){
      Swal.fire(
        'Warning',
        'Please enter valid breakfast',
        'warning'
      )
    }else if(weeklyMenu.breakfastHindi == undefined || weeklyMenu.breakfastHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.breakfastHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.breakfastHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.breakfastHindi.toString().trim())){
     Swal.fire(
       'Warning',
       'Please enter valid breakfast hindi',
       'warning'
     )
   }else if (weeklyMenu.lunch == undefined || weeklyMenu.lunch.toString().trim()=="" ){
      Swal.fire(
        'Warning',
        'Please enter valid lunch  ',
        'warning'
      )
    }else if(weeklyMenu.lunchHindi == undefined || weeklyMenu.lunchHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.lunchHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.lunchHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.lunchHindi.toString().trim())){
     Swal.fire(
       'Warning',
       'Please enter valid lunch hindi',
       'warning'
     )
   }else if (weeklyMenu.eveningSnack == undefined || weeklyMenu.eveningSnack.toString().trim()==""){
      Swal.fire(
        'Warning',
        'Please enter valid evening snack',
        'warning'
      )
    }else if(weeklyMenu.eveningSnackHindi == undefined || weeklyMenu.eveningSnackHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.eveningSnackHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.eveningSnackHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.eveningSnackHindi.toString().trim())){
       Swal.fire(
         'Warning',
         'Please enter valid evening snack hindi',
         'warning'
       )
     }else if (weeklyMenu.dinner == undefined || weeklyMenu.dinner.toString().trim()=="" ){
       Swal.fire(
         'Warning',
         'Please enter valid dinner',
         'warning'
       )
     }else if(weeklyMenu.dinnerHindi == undefined || weeklyMenu.dinnerHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.dinnerHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.dinnerHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.dinnerHindi.toString().trim())){
       Swal.fire(
         'Warning',
         'Please enter valid dinner hindi',
         'warning'
       )
     }else{
     
      this.api.updateWeeklyMenu(weeklyMenu).subscribe(data => {
        if(data["message"]=="Updated Successfully"){
          Swal.fire(
            'Success',
            'Weekly menu item updated successfully',
            'success'
          ).then(()=>{
           // window.location.reload();
            let dismissButton : HTMLElement= document.getElementById('dismiss-close') as HTMLElement;
          dismissButton.click();
          })
         
          
        } else {
          Swal.fire(
            'Warning',
            'Weekly menu item is already exist',
            'warning'
          )
        }
      })
  }
  }
  

  setdata(weeklyMenu: WeeklyMenu) {
    this.weeklyMenu = weeklyMenu;
  }
}
