import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { TransactionDetailsForAdmin } from '../model/transactiondetails-foradmin.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {

  transactionadmin_list: TransactionDetailsForAdmin[];
  searchTransaction: string;
  amount: number;
  totalCredit: number;
  totalDebit: number;
  search : string=null;

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData(){
      this.transactionadmin_list = Array<TransactionDetailsForAdmin>();
      this.api.getAllTransactionForAdmin(this.search).subscribe(data => {
      this.transactionadmin_list = data["allTransactionHistory"];
      this.totalCredit = data["totalCredit"];
      this.totalDebit = data["totalDebit"];
      this.amount = data["amount"];   
    
        },error=>{
          //  alert(JSON.stringify(error));
        })
      }
}
