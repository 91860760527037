import { Component, OnInit } from '@angular/core';
import { User } from './model/user.model';
import { MenuItem } from './model/menuitem.model';
import { TransactionDetailsForAdmin } from './model/transactiondetails-foradmin.model';
import { ApiService } from './service/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'genesis-canteen-admin';
  loginForm: FormGroup;
  isLoggedIn: boolean;
  submitted = false;
  returnUrl: string;
  error: string;
  

  check_credit: boolean = false;
  check_debit: boolean = false;
  user: User;
  user_list: User[];
  menuitem: MenuItem;
  menuitem_list: MenuItem[];
  new_menuitem: MenuItem;
  transactionDetailsForAdmin: TransactionDetailsForAdmin; 
  transactionadmin_list: TransactionDetailsForAdmin[];
  t_type:string;
  amount:number;

  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router,private api:ApiService){
  
  }
   ngOnInit(): void {
  }
  
}


