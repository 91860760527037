import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { StockModel } from '../model/stock.model';
import Swal from 'sweetalert2'



@Component({
  selector: 'app-transaction-out',
  templateUrl: './pending-transaction.component.html',
  styleUrls: ['./pending-transaction.component.css']
})
export class PendingTransactionComponent implements OnInit {
pendingItemList : StockModel[];
stock : StockModel = new StockModel(0, 0,"",0,0,"","",0,"",0);

  constructor(private api : ApiService) { }

  ngOnInit() {
    this.loadOutEntries();
  }
loadOutEntries(){
this.api.getAllTransactionOut().subscribe(data=>{
this.pendingItemList = data["pendingList"];

});

}
updateTransactionOutStatus(status:string, groceryTransactionId:number){

this.stock.groceryTransactionId = groceryTransactionId;
this.stock.status=status;
Swal.fire({
  title: 'Are you sure?',
  text: "You want to update status!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, update status!'
}).then((result) => {
  if (result.value) {
    this.api.updateTransactionOutStatus(this.stock).subscribe(data=>{
      if(data["groceryTransactionId"] == this.stock.groceryTransactionId){
        let index = this.pendingItemList.findIndex(d => d.groceryTransactionId === this.stock.groceryTransactionId); //find index in your array
        this.pendingItemList.splice(index, 1);//remove element from array
      }
      this.stock = new StockModel(0, 0,"",0,0,"","",0,"",0);
       });
    Swal.fire(
      'status updated!',
      'status changed to '+status,
      'success'
    )
  }
})
}
}
