import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { GroceryItem } from '../../model/grocery-item.model';
import { ApiService } from '../../service/api.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../../service/global.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-grocery',
  templateUrl: './new-grocery.component.html',
  styleUrls: ['./new-grocery.component.css']
})
export class NewGroceryComponent implements OnInit, AfterViewInit {
  grocery : GroceryItem = new GroceryItem(0,"",null,"0","",0,"","");

  constructor(private api : ApiService,private globalService:GlobalService) { }

  ngOnInit() {
    
  }
  ngAfterViewInit() {
    console.log("grocery item :")
    console.log(JSON.stringify(this.grocery))
  }

  closeModal(){
    this.grocery.itemName="";
    this.grocery.displayName="";
    this.grocery.itemUnit="0";
    this.grocery.reorderQuantity=null;
  }

  addNewGrocery(addNewGrocery : FormGroup){
    let alphabatConstant : RegExp = /[a-zA-Z]+$/;
    let specialConstant : RegExp = /[!@#$&()-`.+,/\"]/;
    let numberConstant : RegExp = /[0-9]+$/;

    if(this.grocery.itemName == undefined || this.grocery.itemName.toString().trim() == ""){
      Swal.fire(
        'Warning',
        'Please enter valid item name',
        'warning'
      )
    }else if(this.grocery.displayName == undefined || this.grocery.displayName.toString().trim() == "" || alphabatConstant.test(this.grocery.displayName.toString()) || specialConstant.test(this.grocery.displayName.toString()) || numberConstant.test(this.grocery.displayName.toString())){
      Swal.fire(
        'Warning',
        'Please select valid item name hindi',
        'warning'
      )
    }else if(this.grocery.itemUnit == undefined || this.grocery.itemUnit == "0"){
      Swal.fire(
        'Warning',
        'Please select valid measuring unit',
        'warning'
      )
    }else if(this.grocery.reorderQuantity == undefined || this.grocery.reorderQuantity <= 0){
      Swal.fire(
        'Warning',
        'Please enter valid reorder quantity',
        'warning'
      )
    }else {
      this.api.addNewGroceryItem(this.grocery).subscribe(data=>{
        if(data["message"] == "Added Successfully"){
          this.grocery.groceryItemId = data["groceryItemId"];
          this.grocery.quantity=0
          this.globalService.getItemComponent().ItemList.push(this.grocery);
          
          Swal.fire(
            'Success',
            'Grocery Item added successfully',
            'success'
          )
          .then(()=>{
           window.location.reload();
          })

          let dismissButton : HTMLElement= document.getElementById('dismiss-btn') as HTMLElement;
          dismissButton.click();
        }else{
          Swal.fire(
            'Warning',
            'Grocery item is already exist',
            'warning'
          )
        }
      });
    }
  }
  selectMeasure(unit : string){
    this.grocery.itemUnit =unit;

  }
}
