import { Component, OnInit,Input } from '@angular/core';
import { WeeklyMenu } from '../../model/weeklymenu.model';
import { WeekDays } from '../../model/week-days.model';
import { ApiService } from '../../service/api.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../service/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-weekly-menu',
  templateUrl: './new-weekly-menu.component.html',
  styleUrls: ['./new-weekly-menu.component.css']
})
export class NewWeeklyMenuComponent implements OnInit {

  weeklyMenu: WeeklyMenu;
  menu: WeeklyMenu;
  weekday_list: WeekDays[] = [];
  @Input() weeklymenuList: WeeklyMenu[];

    selectedvalue : string = "Select day";
    selectedStatus : string = "Select week";
    constructor(private api : ApiService, private  router:Router,private  globalService:GlobalService) { }

    ngOnInit() {
        
        this.globalService.getWeeklyMenuComponent
        this.weekday_list = Array<WeekDays>();
        this.api.getAllWeekDays().subscribe(data => {
          this.weekday_list = data["weekDays"];
         })
      this.weeklyMenu = new WeeklyMenu(0, 0,"","", "", "","","", "", "","","");
  
    }

    selectStatus(status : string){
      this.weeklyMenu.status=status;
    }

    selectDay(dayId : number){
      this.weeklyMenu.dayId=dayId;
    }


    addWeeklyMenu() {
      let alphabatConstant : RegExp = /[a-zA-Z]/;
      let specialConstant : RegExp = /[!@#$&()-+"]/;
      let numberConstant : RegExp = /[0-9]/;
      
     if(this.weeklyMenu.dayId == undefined || this.weeklyMenu.dayId<=0){
       Swal.fire(
         'Warning',
         'Please select valid day',
         'warning'
       )
     } else  if(this.weeklyMenu.status == undefined || this.weeklyMenu.status==""){
      Swal.fire(
        'Warning',
        'Please select valid week',
        'warning'
      )
    }else if(this.weeklyMenu.breakfast == undefined || this.weeklyMenu.breakfast.toString().trim()=="" ){
       Swal.fire(
         'Warning',
         'Please enter valid breakfast',
         'warning'
       )
     }else if(this.weeklyMenu.breakfastHindi == undefined || this.weeklyMenu.breakfastHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.breakfastHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.breakfastHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.breakfastHindi.toString().trim())){
      Swal.fire(
        'Warning',
        'Please enter valid breakfast hindi',
        'warning'
      )
    }else if (this.weeklyMenu.lunch == undefined || this.weeklyMenu.lunch.toString().trim()=="" ){
       Swal.fire(
         'Warning',
         'Please enter valid lunch  ',
         'warning'
       )
     }else if(this.weeklyMenu.lunchHindi == undefined || this.weeklyMenu.lunchHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.lunchHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.lunchHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.lunchHindi.toString().trim())){
      Swal.fire(
        'Warning',
        'Please enter valid lunch hindi',
        'warning'
      )
    }else if (this.weeklyMenu.eveningSnack == undefined || this.weeklyMenu.eveningSnack.toString().trim()=="" ){
       Swal.fire(
         'Warning',
         'Please enter valid evening snack',
         'warning'
       )
     }else if(this.weeklyMenu.eveningSnackHindi == undefined || this.weeklyMenu.eveningSnackHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.eveningSnackHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.eveningSnackHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.eveningSnackHindi.toString().trim())){
        Swal.fire(
          'Warning',
          'Please enter valid evening snack hindi',
          'warning'
        )
      }else if (this.weeklyMenu.dinner == undefined || this.weeklyMenu.dinner.toString().trim()=="" ){
        Swal.fire(
          'Warning',
          'Please enter valid dinner',
          'warning'
        )
      }else if(this.weeklyMenu.dinnerHindi == undefined || this.weeklyMenu.dinnerHindi.toString().trim()=="" || alphabatConstant.test(this.weeklyMenu.dinnerHindi.toString().trim()) || specialConstant.test(this.weeklyMenu.dinnerHindi.toString().trim()) || numberConstant.test(this.weeklyMenu.dinnerHindi.toString().trim())){
        Swal.fire(
          'Warning',
          'Please enter valid dinner hindi',
          'warning'
        )
      }else{
       this.api.addWeeklyMenu(this.weeklyMenu).subscribe(data => {
         if(data["message"]=="Added Successfully"){
           this.weeklyMenu.dayId = data["dayId"];
           this.weeklyMenu.day = data["day"];
           this.weeklymenuList.push(this.weeklyMenu);
           Swal.fire(
             'Success',
             'Weekly menu item added successfully',
             'success'
           ).then(()=>{
            this.weeklyMenu = new WeeklyMenu(0, 0,"","", "", "","","", "", "","","");
            let dismissButton : HTMLElement= document.getElementById('dismiss-btn') as HTMLElement;
            dismissButton.click();
             window.location.reload();
           })
          
          
         } else {
           Swal.fire(
             'Warning',
             'Weekly menu item is already exist',
             'warning'
           )
         }
       })
   }
  }
}
